import React from 'react';
import { Link } from 'react-router-dom';
import '@fontsource/inter';

export default function Privacy() {
  return (
    <div className='relative h-screen overflow-auto'>
      <div className='max-w-screen-md mx-auto px-4 md:px-10 py-6'>
        {/* Header */}
        <div className='space-y-2 mt-16'>
          <h1 className='text-3xl md:text-4xl mt-4 text-center text-black font-semibold font-inter'>Privacy Policy</h1>
          <p className='text-sm md:text-base text-black text-center font-semibold font-inter'>
            [Shreshta IT Technologies Pvt. Ltd.] ("us", "we", or "our") operates<br/> 
            the [Check Open Resolver] application (the "Service").
          </p>
        </div>

        {/* Scrollable Content */}
        <div className='mt-6 space-y-6 mb-8'>
          <div className='space-y-2'>
            <h2 className='font-bold text-xl md:text-2xl font-inter'>Information Collection and Usage</h2>
            <p className='text-sm md:text-base font-inter'>
              We do not actively collect personal information from users of our Service. The only information that is logged is the web server access logs. These logs may include the IP address, user agent string, and request URI. These logs are used solely for monitoring and maintaining the functionality and security of the Service. They are not used to identify or track individual users.
            </p>
          </div>

          <div className='space-y-2'>
            <h2 className='font-bold text-xl md:text-2xl font-inter'>Web Server Access Logs</h2>
            <p className='text-sm md:text-base font-inter'>
              Access logs are standard in most web hosting environments and are used to record requests made to the server. This information is used for debugging, security, and statistical purposes. The information logged may include:
              <ul className='list-disc pl-5'>
                <li className='font-inter'>IP Address: The IP address of the device making the request.</li>
                <li className='font-inter'>User Agent String: Information about the browser or client making the request.</li>
                <li className='font-inter'>Request URI: The specific resource or URL being requested.</li>
              </ul>
            </p>
          </div>

          <div className='space-y-2'>
            <h2 className='font-bold text-xl md:text-2xl font-inter'>Retention of Data</h2>
            <p className='text-sm md:text-base font-inter'>
              We retain web server access logs for 180 days, in accordance <Link to='https://www.cert-in.org.in/PDF/CERT-In_Directions_70B_28.04.2022.pdf' className='text-blue-600 underline'>CERT-In mandate for storing logs.</Link>
            </p>
          </div>

          <div className='space-y-2'>
            <h2 className='font-bold text-xl md:text-2xl font-inter'>Security of Data</h2>
            <p className='text-sm md:text-base font-inter'>
              We employ appropriate technical and organizational measures to protect the security of the web server access logs. However, no method of electronic storage is 100% secure, and we cannot guarantee absolute security.
            </p>
          </div>

          <div className='space-y-2'>
            <h2 className='font-bold text-xl md:text-2xl font-inter'>Changes to This Privacy Policy</h2>
            <p className='text-sm md:text-base font-inter'>
              We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
            </p>
          </div>

          <div className='space-y-2'>
            <h2 className='font-bold text-xl md:text-2xl font-inter'>Contact Us</h2>
            <p className='text-sm md:text-base font-inter'>
              If you have any questions about this Privacy Policy, please get in touch with us:<br />
              <ul className='list-disc pl-5'>
                <li className='font-inter'>By Email: <Link to='#' className='text-blue-600 underline'>support@shreshtait.com</Link></li>
                <li className='font-inter'>By visiting this page on our website: <Link to='https://shreshtait.com/contact/' className='text-blue-600 underline'>Click here</Link></li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
