import {BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Resources from "./components/Resources"
import Layout from "./components/layout";
import Privacy from "./components/Privacy";
import Mitigation from "./components/Mitigation";



function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Layout><Home/></Layout>}/>
          <Route path="/resources" element={<Layout><Resources/></Layout>}/>
          <Route path="/privacy" element={<Layout><Privacy/></Layout>}/>
          <Route path="/mitigation" element={<Layout><Mitigation/></Layout>}/>
        </Routes>
      </Router>
  );
}

export default App;
