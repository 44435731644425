import React from 'react';
import { Link } from 'react-router-dom';
import '@fontsource/inter';
import { FaArrowRightLong } from "react-icons/fa6";

export default function Resources() {
  return (
    <div className="relative h-screen overflow-auto">
      <div className="w-full h-full flex flex-col">
        <h1 className="text-3xl md:text-4xl font-semibold font-inter mt-20 ml-4 md:ml-12">Resources page</h1>
        <div className="mt-8 md:mt-14 px-4 md:px-0">
          <div className="container mx-auto text-gray-500 md:px-12 xl:px-0">
            <div className="grid gap-8 md:gap-16 md:w-3/4 lg:w-full lg:grid-cols-3 mb-14">
              <div className="bg-white rounded-2xl shadow-xl px-6 py-8 sm:px-12 lg:px-8">
                <div className="mb-8 space-y-4">
                  <h3 className="text-2xl md:text-3xl font-semibold text-purple-900 font-inter">Open Resolver</h3>
                  <p className="mb-6 font-inter">Understanding security risks and best practices.</p>
                  <Link to="https://shreshtait.com/blog/2023/04/open-resolvers-understanding-security-risks-and-best-practices/" className="font-medium flex text-purple-600" target="_blank" rel="noopener noreferrer">Know more<FaArrowRightLong className="ml-2 mt-1" /></Link>
                </div>
              </div>

              <div className="bg-white rounded-2xl shadow-xl px-6 py-8 sm:px-12 lg:px-8">
                <div className="mb-8 space-y-4">
                  <h3 className="text-2xl md:text-3xl font-semibold text-purple-900 font-inter">KINDNS</h3>
                  <p className="mb-6 font-inter">Knowledge-Sharing and Instantiating Norms for DNS and Naming Security(KINDNS).</p>
                  <Link to="https://kindns.org/" className="font-medium flex text-purple-600" target="_blank" rel="noopener noreferrer">Know more<FaArrowRightLong className="ml-2 mt-1" /></Link>
                </div>
              </div>

              <div className="bg-white rounded-2xl shadow-xl px-6 py-8 sm:px-12 lg:px-8">
                <div className="mb-8 space-y-4">
                  <h3 className="text-2xl md:text-3xl font-semibold text-purple-900 font-inter">Open Resolvers and why do they still exist?</h3>
                  <Link to="https://indico.dns-oarc.net/event/50/contributions/1063/attachments/1020/1977/Open%20Resolvers%20and%20why%20do%20they%20still%20exist%20-%20APRICOT%202024%20DNS-OARC%20Community%20Meeting%20.pdf" className="font-medium flex text-purple-600" target="_blank" rel="noopener noreferrer">Know more<FaArrowRightLong className="ml-2 mt-1" /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
