import React, { useState, useRef } from 'react';
import axios from 'axios';
import '@fontsource/inter';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

const RECAPTCHA_SITE_KEY = "6LeUCfMpAAAAAOdS-EkrbRnBiiJxGyiuUxzUJrA1"; // Replace with your Site Key

export default function Home() {
  const [ip, setIp] = useState("");
  const [message, setMessage] = useState("");
  const [clicked, setClicked] = useState(false);
  const recaptchaRef = useRef();

  async function checkResolver(token) {
    if (!token) {
      setMessage("Please complete the reCAPTCHA.");
      return;
    }

    setClicked(true);
    setIp("Checking, please wait..");
    setMessage("");

    try {
      const response = await axios.post('/api/result', {
        recaptchaToken: token
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setIp("Your IP: " + response.data.ip);
      setMessage(response.data.message);
    } catch (error) {
      console.log(error);
      setIp("An error occurred: " + (error.response ? error.response.data.message : error.message));
    }
  }

  const handleRecaptcha = (token) => {
    checkResolver(token);
  }

  return (
    <div className='relative h-screen overflow-auto'>
      <div className='max-w-screen-md mx-auto h-full mt-10 flex flex-col justify-center space-y-8 px-4 py-6 text-black  font-inter'>
        <h1 className='font-semibold font-inter text-3xl sm:text-4xl'>Check Open Resolver</h1>
        <div className='text-lg sm:text-xl mt-0 font-inter' style={{ letterSpacing: '0.05em' }}>
          An open resolver is a DNS server which is willing to resolve recursive DNS lookups for anyone on the Internet.
          They are exploited for malicious activities like Distributed Denial of Service Attacks (DDoS).
          <Link to="https://shreshtait.com/blog/2023/04/open-resolvers-understanding-security-risks-and-best-practices/" className='text-blue-600 font-inter' target="_blank" rel="noopener noreferrer"> Read more</Link><br/><br/>
          To check if your Internet router is an Open resolver, click "Check Now".
        </div>
        <div className='flex flex-col items-center mt-4'>
          <button
            className="w-48 rounded-lg bg-primary-600 py-3 px-6 font-sans text-md font-bold uppercase text-white shadow-default transition-all hover:shadow-lg focus:opacity-85 focus:shadow-none active:opacity-85 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none mb-4"
            data-ripple-light="true" onClick={() => recaptchaRef.current.execute()}>
            Check Now
          </button>
          {clicked && <h2 className='text-lg sm:text-xl font-semibold font-inter mt-4 text-center'>{ip}</h2>}
          <h2 className='text-[16px] sm:text-[18px] font-semibold font-inter mt-4 text-center' style={{color: message.includes("Hurray") ? 'green' : 'red' }}>
            {message}
          </h2>
          {message.includes("Oh no, your router is an open resolver") && (
            <div className='mt-4'>
              <Link to="/mitigation" target="_blank" rel="noopener noreferrer" className='text-blue-600 font-inter'>
                Click here for instructions
              </Link>
            </div>
          )}
          <div className='mt-4'>
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITE_KEY}
              size="invisible"
              ref={recaptchaRef}
              onChange={handleRecaptcha}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
