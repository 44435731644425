import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function Navbar() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState('');

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  return (
    <nav className="fixed top-0 left-0 w-full z-50 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          {/* Secondary Navigation Links */}
          <div className="ml-auto flex items-center space-x-4 sm:space-x-10">
            <Link
              to="/"
              className={`text-center font-inter text-[18px] sm:text-[22px] inline-flex items-center justify-center font-semibold text-gray-900 hover:text-black hover:border-b-2 hover:border-primary-500 ${
                activeLink === '/' ? 'border-b-2 border-black text-black' : ''
              }`}
            >
              Home
            </Link>
            <Link
              to="/mitigation"
              className={`text-center font-inter text-[18px] sm:text-[22px] inline-flex items-center justify-center font-semibold text-gray-900 hover:text-black hover:border-b-2 hover:border-primary-500 ${
                activeLink === '/mitigation' ? 'border-b-2 border-black text-black' : ''
              }`}
            >
              Mitigation
            </Link>
            <Link
              to="/resources"
              className={`text-center font-inter text-[18px] sm:text-[22px] inline-flex items-center justify-center font-semibold text-gray-900 hover:text-black hover:border-b-2 hover:border-primary-500 ${
                activeLink === '/resources' ? 'border-b-2 border-black text-black' : ''
              }`}
            >
              Resources
            </Link>
            {/* <Link
              to="/privacy"
              className={`text-center font-inter text-[18px] sm:text-[22px] inline-flex items-center justify-center font-semibold text-gray-900 hover:text-black hover:border-b-2 hover:border-primary-500 ${
                activeLink === '/privacy' ? 'border-b-2 border-black text-black' : ''
              }`}
            >
              Privacy
            </Link> */}
          </div>
        </div>
      </div>
    </nav>
  );
}
