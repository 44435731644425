import React from 'react';
import '@fontsource/inter';

export default function Mitigation() {
  return (
    <div className="relative h-screen w-screen">
      {/* Scrollable Content */}
      <div className="pt-10 pb-14 h-full overflow-auto">
        <div className="max-w-screen-md mx-auto px-10">
          <div className="px-4 md:px-8 lg:px-12">
            {/* Header */}
            <div className="w-full h-[10%] bg-white space-y-2 mt-16">
              <h1 className="text-[25px] mt-4 text-center text-black font-semibold font-inter">
                Steps to follow, If your router is an open resolver
              </h1>
            </div>
            <ul className="list-disc ml-4 md:ml-8 space-y-4 mt-8">
              <li className="mb-4">
                <h3 className="text-lg md:text-xl lg:text-2xl font-inter mb-2">
                  Identify the Router Manufacturer:
                </h3>
                <p className="font-inter">
                  Look for the brand and model information on your router. This information is usually found on a label on the back or bottom of the device.
                </p>
              </li>
              <li className="mb-4">
                <h3 className="text-lg md:text-xl lg:text-2xl font-inter mb-2">
                  Contact the Manufacturer:
                </h3>
                <p className="font-inter">
                  Visit the manufacturer’s website to find contact information for technical support. Call or email the manufacturer’s technical support team and inform them that your router has been identified as an open resolver. Request assistance in updating the firmware or securing the device.
                </p>
              </li>
              <li className="mb-4">
                <h3 className="text-lg md:text-xl lg:text-2xl font-inter mb-2">
                  Contact Your Internet Service Provider (ISP):
                </h3>
                <p className="font-inter">
                  If your router was provided by your ISP, reach out to their customer support. Inform them about the open resolver issue and request their help in securing the router. They may provide instructions or send a technician to resolve the issue.
                </p>
              </li>
              <li className="mb-4">
                <h3 className="text-lg md:text-xl lg:text-2xl font-inter mb-2">
                  Follow Manufacturer’s Instructions:
                </h3>
                <p className="font-inter">
                  If the manufacturer provides instructions or support documentation, carefully follow the steps to update the firmware and secure your router. If you receive remote assistance, ensure you understand each step being taken to secure your device.
                </p>
              </li>
              <li className="mb-4">
                <h3 className="text-lg md:text-xl lg:text-2xl font-inter mb-2">
                  Request Remote Assistance:
                </h3>
                <p className="font-inter">
                  Some manufacturers and ISPs offer remote assistance services. If available, request a technician to remotely access your router and fix the open resolver issue.
                </p>
              </li>
              <li className="mb-4">
                <h3 className="text-lg md:text-xl lg:text-2xl font-inter mb-2">
                  Schedule a Technician Visit:
                </h3>
                <p className="font-inter">
                  If remote assistance is not available or if the issue persists, schedule a technician visit. They can physically inspect and secure your router.
                </p>
              </li>
              <li className="mb-4">
                <h3 className="text-lg md:text-xl lg:text-2xl font-inter mb-2">
                  Document the Issue:
                </h3>
                <p className="font-inter">
                  Take note of any actions taken and keep records of your communications with the manufacturer or ISP. This documentation may be helpful if the issue reoccurs.
                </p>
              </li>
              <li className="mb-4">
                <h3 className="text-lg md:text-xl lg:text-2xl font-inter mb-2">
                  Regularly Check for Updates:
                </h3>
                <p className="font-inter">
                  Periodically check the manufacturer’s website for firmware updates and apply them as needed to keep your router secure.
                </p>
              </li>
              <li className="mb-4">
                <h3 className="text-lg md:text-xl lg:text-2xl font-inter mb-2">
                  Seek Local Help:
                </h3>
                <p className="font-inter">
                  If you have a trusted local computer technician or a tech-savvy friend or family member, ask for their assistance in securing your router.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
